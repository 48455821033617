/**
 * @file Application top level component file
 */
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import '@fortawesome/fontawesome-free/css/all.min.css';

import { StudiesContext, StudiesProvider } from 'containers/StudiesContext';
import { ScannersProvider } from 'containers/ScannersContext';
import { SessionContext, SessionLoader } from 'containers/SessionContext';
import { EventsProvider } from 'containers/EventsContext';
import { UnauthorizedSso } from 'containers/LoginPage/unauthorizedSso';

import Header from 'containers/Header';
import Footer from 'components/footer';
import LoginPage from 'containers/LoginPage';
import StudiesListPage from 'containers/StudiesListPage';
import StudyPage from 'containers/StudyPage';
import ScannerPage from 'containers/ScannerPage';

/**
 * Application top level component
 *
 * @returns {object} React element
 */
function App() {
  return (
    <div className='App'>
      <SessionLoader>
        <SessionContext.Consumer>
          {({ sessionData, samlUnauthorized, logout }) => {
            return (
              <Router
                authorized={sessionData.isAuthorized}
                samlUnauthorized={samlUnauthorized}
                logout={logout}
              />
            );
          }}
        </SessionContext.Consumer>
      </SessionLoader>
    </div>
  );
}

/**
 * @param  {boolean} {authorized} Flag whether user is authorized to access Apollo
 * @returns {object} React routed application
 */
const Router = ({ authorized, samlUnauthorized, logout }) => {
  /** Route while waiting for authorization confirmation
   *
   * @returns {object} React element
   */
  let Routes = () => (
    <Switch>
      <Route path='/'>
        <div className='pageloader'>
          <span className='title'>Pageloader</span>
        </div>
      </Route>
    </Switch>
  );

  if (authorized) {
    Routes = () => (
      <EventsProvider eventEndpoint='/api/events'>
        <StudiesProvider>
          <StudiesContext.Consumer>
            {({ studies, getSingleStudy, tableRef }) => (
              <Switch>
                <Route
                  path='/scanner/:scannerId'
                  render={props => <ScannerPage {...props} />}
                />
                {(studies.unread.length > 0 || studies.read.length > 0) && (
                  <Route
                    path='/study/:studyId'
                    render={props => (
                      <StudyPage
                        {...props}
                        studyData={getSingleStudy(props.match.params.studyId)}
                      />
                    )}
                  />
                )}
                {(studies.read || studies.unread) && (
                  <Route
                    path='/'
                    render={props => (
                      <StudiesListPage
                        {...props}
                        studies={studies}
                        tableRef={tableRef}
                      />
                    )}
                  />
                )}
              </Switch>
            )}
          </StudiesContext.Consumer>
        </StudiesProvider>
      </EventsProvider>
    );
  } else if (authorized === false) {
    Routes = () => (
      <Switch>
        <Route path='/' component={LoginPage} />
      </Switch>
    );
  }

  if (samlUnauthorized()) {
    Routes = () => (
      <Switch>
        <UnauthorizedSso logout={logout} />
      </Switch>
    );
  }

  return (
    <ScannersProvider authorized={authorized}>
      <Header />
      <Routes />
      <Footer />
    </ScannersProvider>
  );
};

Router.propTypes = {
  authorized: PropTypes.bool
};

Router.defaultProps = {
  authorized: undefined
};

export default App;
