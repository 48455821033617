/**
 * @file date formatting
 */

/**
 * Function for altering the timestamp formatting
 *
 * @param {string} timestamp timestamp to format
 * @returns {string} Correct timestamp formatting
 */
export const formatDate = timestamp => {
  let regex = /([0-3][0-9]\/[0-1][0-9]\/[0-9][0-9][0-9][0-9] [0-2][0-9]:[0-5][0-9])/;
  if (regex.test(timestamp)) {
    return timestamp;
  }
  let newTimestamp =
    timestamp.substring(6, 8) +
    '/' +
    timestamp.substring(4, 6) +
    '/' +
    timestamp.substring(0, 4) +
    ' ' +
    timestamp.substring(8, 10) +
    ':' +
    timestamp.substring(10, 12);
  return newTimestamp;
};

/**
 * Function for getting the current date and time for header and for pdf report
 *
 * @param {boolean} toPDF whether to return time with seconds or not
 * @returns {string} Date and time as a string
 */
export const getDateTime = toPDF => {
  let dateobj = new Date();
  let year = dateobj.getFullYear();
  let month = String(dateobj.getMonth() + 1).padStart(2, '0');
  let day = String(dateobj.getDate()).padStart(2, '0');
  let hour = String(dateobj.getHours()).padStart(2, '0');
  let minutes = String(dateobj.getMinutes()).padStart(2, '0');
  let seconds = String(dateobj.getSeconds()).padStart(2, '0');

  let dtStr = '';
  if (toPDF) {
    dtStr = year + month + day + hour + minutes;
  } else {
    let dateStr = day + '/' + month + '/' + year;
    let timeStr = hour + ':' + minutes + ':' + seconds;
    dtStr = dateStr + ' ' + timeStr;
  }
  return dtStr;
};
