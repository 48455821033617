/**
 * @file Application Session Context handling
 */
import React, { useState, useEffect } from 'react';
import axios from 'axios';

export const SessionContext = React.createContext();

/** Session loader element
 *
 * @function
 * @param  {object} children React children as props
 * @returns {object} React element
 */
export const SessionLoader = ({ children }) => {
  axios.interceptors.response.use(
    response => response,
    error => {
      if (error) {
        if (
          sessionData.isAuthorized &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          setSessionData({ ...sessionData, isAuthorized: false, username: '' });
        }
      }
      return Promise.reject(error);
    }
  );
  /** HTTP request for checking if user's cookie is still valid
   */
  const checkSession = async () => {
    try {
      const resp = await axios('/api/logged_in');
      if (resp.data.logged_in === true) {
        setSessionData({
          isAuthorized: true,
          isExternalUser: resp.data.external_user,
          username: resp.data.username
        });
      } else if (resp.data.saml_unauthorized === true) {
        setSessionData({
          isAuthorized: false,
          isExternalUser: true,
          username: resp.data.username
        });
      }
    } catch {
      setSessionData({ isAuthorized: false, username: '' });
    }
  };
  /** Logout request function
   */
  const sessionLogout = async () => {
    await axios('/api/logout');
  };

  /** External logout redirect
   */
  const externalLogout = () => {
    window.location.href = '/api/saml/slo';
  };

  /** Login response handling function
   *
   *@param {string} username Logged-in user username
   */
  const login = username => {
    setSessionData({ ...sessionData, isAuthorized: true, username: username });
  };

  /** Logout response handling function
   *
   * @param {object} event Default button click event in form
   *
   */
  const logout = async event => {
    event.preventDefault();
    if (sessionData.isExternalUser) {
      externalLogout();
      return;
    }
    await sessionLogout();
    setSessionData({ ...sessionData, isAuthorized: false, username: '' });
  };

  const [sessionData, setSessionData] = useState({
    isAuthorized: undefined,
    isExternalUser: false,
    username: ''
  });

  useEffect(() => {
    checkSession();
  }, []);

  /** Function for checking whether user is logged in by SSO but not authorized for Apollo
   *
   * @returns {boolean} user is logged in by SSO but not authorized for Apollo
   */
  const samlUnauthorized = () => {
    return sessionData.isExternalUser && sessionData.isAuthorized === false;
  };

  return (
    <SessionContext.Provider
      value={{ sessionData, login, logout, samlUnauthorized }}
    >
      {children}
    </SessionContext.Provider>
  );
};
