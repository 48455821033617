/**
 * @file Cells file for study list page
 */
import React from 'react';

/** Filter for name column in Study list page
 *
 * @function
 * @param  {string} cell value which has to be filtered
 * @returns {object} React regular cell element
 */
export const RegularCell = cell => {
  return (
    <td
      {...cell.getCellProps()}
      className={cell.column.className}
      onClick={cell.onClick}
      aria-hidden='true'
    >
      {cell.render('Cell')}
    </td>
  );
};

/** Cell for read/unread column in Study list page
 *
 * @function
 * @param  {string} cell for user to mark study as read/unread
 * @returns {object} React read/unread cell element
 */
export const ReadCell = cell => {
  if (cell.read) {
    return (
      <td {...cell.getCellProps()} className={cell.column.className}>
        <i
          className='left far fa-circle readunreadbtn'
          onClick={cell.onClick}
          aria-hidden='true'
        ></i>
        {cell.render('Cell')}
      </td>
    );
  } else {
    return (
      <td {...cell.getCellProps()} className={cell.column.className}>
        <i
          className='left fas fa-circle readunreadbtn'
          onClick={cell.onClick}
          aria-hidden='true'
        ></i>
        {cell.render('Cell')}
      </td>
    );
  }
};

/** Filter for study date time column in Study list page
 *
 * @function
 * @param  {string} cell value which has to be filtered
 * @returns {object} React disclaimer column cell element
 */
export const StudyDateTimeCell = cell => {
  return (
    <td
      {...cell.getCellProps()}
      className={cell.column.className}
      onClick={cell.onClick}
      aria-hidden='true'
    >
      <span>{cell.displayname}</span>
    </td>
  );
};

/** Filter for name column in Study list page
 *
 * @function
 * @param  {string} cell value which has to be filtered
 * @returns {object} React disclaimer column cell element
 */
export const DisclaimerCell = cell => {
  return (
    <td
      {...cell.getCellProps()}
      className={cell.column.className}
      onClick={cell.onClick}
      aria-hidden='true'
    >
      <span>{cell.render('Cell')}</span>
    </td>
  );
};

/** React-table Triage cell with the icon or spinner indicaton
 *
 * @function
 * @param  {string} cell value which has to be displayed
 * @returns {object} React triage cell element
 */
export const TriageCell = cell => {
  if (!cell || cell.value === undefined) {
    return (
      <td
        className={cell.column.className}
        onClick={cell.onClick}
        aria-hidden='true'
      >
        Undefined
      </td>
    );
  }
  if (cell.displayname.toLowerCase() === 'processing')
    return (
      <td
        {...cell.getCellProps()}
        className={`${cell.column.className} processing`}
        onClick={cell.onClick}
        aria-hidden='true'
      >
        <i
          className={'left fas fa-spinner fa-pulse'}
          style={{ border: 0, marginRight: '5px', marginLeft: '2px' }}
        />
        <span>Processing</span>
      </td>
    );
  return (
    <td
      {...cell.getCellProps()}
      className={`${cell.column.className} ${cell.displayname.toLowerCase()}`}
      onClick={cell.onClick}
      aria-hidden='true'
    >
      {
        <i
          className='left far fa-circle'
          style={{ color: `#${cell.color}` }}
        ></i>
      }
      {cell.displayname}
    </td>
  );
};
