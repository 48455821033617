/**
 * @file Study list table container
 */
import React, { useState } from 'react';
import shortid from 'shortid';
import axios from 'axios';

import {
  RegularCell,
  ReadCell,
  StudyDateTimeCell,
  TriageCell,
  DisclaimerCell
} from './cells';

/** A table containing either read or unread studies for the Study list page
 *
 * @function
 * @param {object} parentInstance the instance object containing table properties
 * @param {boolean} read indicates whether this is the read or unread table
 * @param {string} tableId ID of the table
 * @param {string} tableHeaderText text to display in table header
 * @param {object} history history object for redirection to study page
 * @returns {object} StudyListTable element
 */
export function StudyListTable({
  parentInstance,
  read,
  tableId,
  tableHeaderText,
  history
}) {
  const [tableShow, setTableShow] = useState(true);

  /** Redirection to study function
   *
   * @param {number} studyId id of study page to open
   */
  const redirectToStudy = studyId => {
    history.push(`/study/${studyId}`);
  };
  /** Function to flip study's read status
   *
   * @param {number} studyId id of study page to open
   */
  const flipReadStatus = async studyId => {
    await axios(`/api/study/${studyId}/flipReadStatus`);
  };
  /** Function to collapse/show study lists on click
   */
  const flipTableShow = () => {
    setTableShow(!tableShow);
  };
  return (
    <table
      id={tableId}
      data-testid={tableId}
      {...parentInstance.getTableProps()}
      className='table-filter table is-hoverable is-fullwidth'
    >
      <thead className='studyListTableHeader'>
        <tr onClick={() => flipTableShow()}>
          <th colSpan={`${parentInstance.columns.length}`}>
            {tableShow && <i className='fa fa-chevron-circle-up' />}
            {!tableShow && <i className='fa fa-chevron-circle-down' />}
            <span> {tableHeaderText}</span>
          </th>
        </tr>
      </thead>
      <tbody {...parentInstance.getTableBodyProps()}>
        {tableShow &&
          parentInstance.rows.map(row => {
            parentInstance.prepareRow(row);
            if (row.original.status.read === read) {
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    if (cell.column.id === 'Read/Unread') {
                      return (
                        <ReadCell
                          {...cell}
                          key={shortid.generate()}
                          onClick={() => flipReadStatus(row.original.studyId)}
                          read={read}
                        />
                      );
                    }
                    if (cell.column.id === 'studydatetime') {
                      return (
                        <StudyDateTimeCell
                          {...cell}
                          key={shortid.generate()}
                          displayname={
                            parentInstance.data[row.index].studydatetimeFormat
                          }
                          onClick={() => redirectToStudy(row.original.studyId)}
                        />
                      );
                    }
                    if (cell.column.id === 'triage') {
                      return (
                        <TriageCell
                          {...cell}
                          key={shortid.generate()}
                          isProcessing={false}
                          color={parentInstance.data[row.index].triageColor}
                          displayname={
                            parentInstance.data[row.index].triageName
                          }
                          onClick={() => redirectToStudy(row.original.studyId)}
                        />
                      );
                    }
                    if (cell.column.id === 'disclaimer') {
                      return (
                        <DisclaimerCell
                          {...cell}
                          key={shortid.generate()}
                          onClick={() => redirectToStudy(row.original.studyId)}
                        />
                      );
                    }
                    return (
                      <RegularCell
                        {...cell}
                        key={shortid.generate()}
                        onClick={() => redirectToStudy(row.original.studyId)}
                      />
                    );
                  })}
                </tr>
              );
            } else {
              return null;
            }
          })}
      </tbody>
    </table>
  );
}
