/**
 * @file Study list page container
 */
import React from 'react';
import { useTable, useFilters, useSortBy } from 'react-table';

import { Columns } from './columns';
import { NameFilter } from './filters';
import { StudyListTable } from './table';

/** Study list table data format function
 *
 * @param {object} studyDisc disclaimer object from the study to check
 * @returns {string} Disclaimer
 */
const getStudyListWarning = studyDisc => {
  if (studyDisc.ageDisclaimer || !studyDisc.scannerValid) {
    return 'NOT FOR CLINICAL USE';
  }
  return '  ';
};

/** Get modified study list
 *
 * @param {object} studiesList the list of studies
 * @param {object} outdata list to hold the modified studies
 * @returns {Array} data the modified studies
 */
const getTableData = (studiesList, outdata) => {
  studiesList.forEach(study => {
    let obj = {
      patientname: study.patientName,
      patientid: study.patientId,
      studydatetime: study.timestampOri,
      studydatetimeFormat: study.timestamp,
      scanner: study.scannerDescription,
      studysummary: study.studyDescription,
      triage: study.priorityId,
      triageName: study.priority,
      triageColor: study.priorityColor,
      studyId: study.studyId,
      modality: study.modality,
      disclaimer: getStudyListWarning(study.clinicalUseDisclaimer),
      status: study.status
    };
    outdata.push(obj);
  });
  return outdata;
};

/**
 * Application top level component
 *
 * @returns {object} React element
 */
const StudiesListPage = ({ history, studies, tableRef }) => {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: NameFilter
    }),
    []
  );

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  const data = React.useMemo(() => {
    var outdata = [];
    outdata = getTableData(studies.unread, outdata);
    outdata = getTableData(studies.read, outdata);
    return outdata;
  }, [studies]);

  const columns = React.useMemo(() => Columns, []);

  const instance = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      autoResetSortBy: !tableRef.current,
      autoResetFilters: !tableRef.current
    },
    useFilters,
    useSortBy
  );

  return (
    <div className='has-navbar-fixed-top'>
      <section className='section study-list'>
        <div className='tableContainerParent'>
          <div className='tableContainerChild'>
            <table
              id='studyListTableTop'
              {...instance.getTableProps()}
              className='table-filter table is-hoverable is-fullwidth'
            >
              <thead className='studylist-headers'>
                <tr>
                  {instance.columns.map(column => (
                    <th
                      {...column.getHeaderProps()}
                      className={column.className}
                    >
                      <div {...column.getSortByToggleProps()}>
                        {column.Header !== 'Read/Unread'
                          ? column.render('Header')
                          : ''}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' 🔽' // TODO: substitute for better icons
                              : ' 🔼'
                            : ''}
                        </span>
                      </div>
                      <div>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <div className='tableContainerParent'>
          <div className='tableContainerChild'>
            <StudyListTable
              parentInstance={instance}
              read={false}
              tableId='studyListTableUnread'
              tableHeaderText={`Number of UNREAD STUDIES: ${
                data.filter(a => !a.status.read).length
              } of ${data.length}`}
              history={history}
            />
          </div>
        </div>
        <div className='tableContainerParent'>
          <div className='tableContainerChild'>
            <StudyListTable
              parentInstance={instance}
              read={true}
              tableId='studyListTableRead'
              tableHeaderText={`Number of READ STUDIES: ${
                data.filter(a => a.status.read).length
              } of ${data.length}`}
              history={history}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default StudiesListPage;
