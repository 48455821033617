/**
 * @file Login page container
 */
import React, { useState, useContext } from 'react';
import axios from 'axios';
import { SessionContext } from 'containers/SessionContext';

/** Login page rendering function
 *
 * @returns {object} React element
 */
const LoginPage = () => {
  const sessionContext = useContext(SessionContext);
  const [loginState, setLoginState] = useState({
    username: '',
    password: '',
    errorMessage: '',
    loading: false
  });

  /** Login page login request function
   *
   * @param {object} event button element default event
   */
  const requestLogin = async event => {
    event.preventDefault();
    if (loginState.username.length < 6 || loginState.username.length > 20) {
      setLoginState(oldState => ({
        ...oldState,
        errorMessage: 'Username must be between 6 and 20 characters'
      }));
      return;
    }
    if (loginState.password.length < 6 || loginState.password.length > 20) {
      setLoginState(oldState => ({
        ...oldState,
        errorMessage: 'Password must be between 6 and 20 characters'
      }));
      return;
    }
    setLoginState(oldState => ({
      ...oldState,
      loading: true
    }));
    await axios
      .post('/api/login', {
        username: loginState.username,
        password: loginState.password
      })
      .then(response => {
        if (response.data.error) {
          setLoginState(oldState => ({
            ...oldState,
            errorMessage: response.data.message
          }));
          return;
        }
        sessionContext.login(loginState.username);
      })
      .catch(err => {
        if (err.response) {
          if (err.response.data) {
            setLoginState(oldState => ({
              ...oldState,
              errorMessage: err.response.data.message
            }));
          }
        }
      })
      .finally(() => {
        setLoginState(oldState => ({
          ...oldState,
          loading: false
        }));
      });
  };

  /** Login page state username setting function
   *
   * @param {object} event input field change event
   */
  const setUsername = event => {
    setLoginState({ ...loginState, username: event.target.value });
  };

  /** Login page state password setting function
   *
   * @param {object} event input field change event
   */
  const setPassword = event => {
    setLoginState({ ...loginState, password: event.target.value });
  };

  /** External Identity provider login button
   *
   * @function
   * @param {string} link to redirect for SSO
   * @param {string} text to display on the SSO button
   * @returns {object} React element
   */
  const ExternalButton = ({ link, text }) => (
    <div className='has-text-centered' style={{ paddingBottom: '10px' }}>
      <a className='button' href={link}>
        <span>{text}</span>
      </a>
    </div>
  );

  return (
    <section className='hero is-fullheight'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns is-centered'>
            <div className='column is-5-tablet is-4-desktop is-3-widescreen'>
              <form className='box'>
                <ExternalButton
                  link={'/api/saml/sso'}
                  text={`Sign In With SAML`}
                />
                <h6 className='subtitle is-6 has-text-centered'>or</h6>
                <div className='field'>
                  <label htmlFor='username-input' className='label'>
                    Username
                  </label>
                  <div className='control has-icons-left'>
                    <input
                      id='username-input'
                      value={loginState.username}
                      type='text'
                      placeholder='username'
                      className='input'
                      required
                      onChange={setUsername}
                      disabled={loginState.loading}
                    />
                    <span className='icon is-small is-left'>
                      <i className='fa fa-envelope'></i>
                    </span>
                  </div>
                </div>
                <div className='field'>
                  <label htmlFor='password-input' className='label'>
                    Password
                  </label>
                  <div className='control has-icons-left'>
                    <input
                      id='password-input'
                      value={loginState.password}
                      type='password'
                      placeholder='*******'
                      className='input'
                      required
                      onChange={setPassword}
                      disabled={loginState.loading}
                    />
                    <span className='icon is-small is-left'>
                      <i className='fa fa-lock'></i>
                    </span>
                  </div>
                  {loginState.errorMessage && (
                    <div className='control'>
                      <label className='label'>{loginState.errorMessage}</label>
                    </div>
                  )}
                </div>
                <div className='field'>
                  <button
                    onClick={requestLogin}
                    className='button is-dark active'
                    disabled={loginState.loading}
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
