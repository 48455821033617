/**
 * @file Patient data component
 */
import React from 'react';

/**
 * Patient data component for study page
 *
 * @returns {object} React element
 */
const PatientData = ({
  patientName,
  patientId,
  timestamp,
  modality,
  scannerDescription,
  triage,
  triageColor
}) => {
  return (
    <div className='column is-three-quarters'>
      <div className='columns'>
        <div className='column'>
          <h3>Patient name</h3>
          <span id='pName'>{patientName}</span>
        </div>
        <div className='column'>
          <h3>Patient ID</h3>
          <span id='pId'>{patientId}</span>
        </div>
        <div className='column'>
          <h3>Study date/time</h3>
          <span id='resultDate'>{timestamp}</span>
        </div>
      </div>
      <div className='columns'>
        <div className='column'>
          <h3>Modality</h3>
          <span id='resultModality'>{modality}</span>
        </div>
        <div className='column'>
          <h3>Scanner</h3>
          <span id='resultScanner'>{scannerDescription}</span>
        </div>
        <div className='column'>
          <h3>Triage</h3>
          <div id='pPriority' className={`priority ${triage.toLowerCase()}`}>
            <span id='priorityTxt'>{triage}</span>
            {triage === 'Processing' && (
              <i
                className='fas fa-spinner fa-pulse'
                style={{ border: 0, width: '15%' }}
              ></i>
            )}
            {triage !== 'Processing' && (
              <i
                className='far fa-circle 2'
                style={{ color: `#${triageColor}` }}
              ></i>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientData;
