/**
 * @file Sequence list component
 */
import React from 'react';
import shortid from 'shortid';
import { SequenceItem } from 'components/sequenceList/sequenceItem';

/**
 * Study page top level component
 *
 * @function
 * @param {object} studies from the studies context
 * @returns {object} React element
 */
export const SequenceList = ({
  completedSequences,
  processingSequences,
  noOverlayFunction,
  viewerKey
}) => {
  return (
    <div className='column is-one-quarter'>
      <h3>Sequences</h3>
      <ul id='sequenceList' className='sequence-list'>
        {completedSequences.map(sequence => {
          return (
            <SequenceItem
              sequence={sequence}
              isCompleted={true}
              clickFunction={noOverlayFunction}
              isSelected={
                viewerKey.seriesId === sequence._id &&
                viewerKey.findingIndex === false
              }
              key={shortid.generate()}
            />
          );
        })}
        {processingSequences.map(sequence => {
          return (
            <SequenceItem
              key={shortid.generate()}
              sequence={sequence}
              isCompleted={false}
              clickFunction={noOverlayFunction}
              isSelected={
                viewerKey.seriesId === sequence._id && !viewerKey.findingIndex
              }
            />
          );
        })}
      </ul>
    </div>
  );
};
