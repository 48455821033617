/**
 * @file Header logic container
 */
import React from 'react';

import { SessionContext } from 'containers/SessionContext';
import { ScannersContext } from 'containers/ScannersContext';
import HeaderComponent from 'components/header';

/** Header top level component
 *
 * @returns {object} React element
 */
const Header = () => {
  return (
    <SessionContext.Consumer>
      {({ sessionData, logout }) => (
        <ScannersContext.Consumer>
          {({ scanners }) => (
            <HeaderComponent
              isAuthorized={sessionData.isAuthorized}
              username={sessionData.username}
              scanners={scanners}
              logout={logout}
            />
          )}
        </ScannersContext.Consumer>
      )}
    </SessionContext.Consumer>
  );
};

export default Header;
