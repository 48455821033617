/**
 * @file Export PDF button component
 */
import React, { useState } from 'react';
import axios from 'axios';

import { getDateTime } from 'utils/dateFormat';

/**
 * PDF button component
 *
 * @function
 * @param {number} studyId study id of study to be exported
 * @returns {object} React element
 */
export const PdfButton = ({ studyId, enabled }) => {
  const [exportStatus, setExportStatus] = useState({
    loading: false,
    error: false
  });

  /**
   * Function for downloading a pdf of the study
   *
   * @function
   */
  const downloadPdf = async () => {
    setExportStatus({
      ...exportStatus,
      loading: true
    });
    await axios({
      url: `/api/study/${studyId}/pdf/${getDateTime(true)}`,
      method: 'GET',
      responseType: 'blob' // important
    })
      .then(response => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.click();
        setExportStatus({
          error: false,
          loading: false
        });
      })
      .catch(err => {
        setExportStatus({
          loading: false,
          error: err
        });
      });
  };

  return (
    <button
      disabled={!enabled}
      title={enabled ? '' : 'No findings to export'}
      onClick={downloadPdf}
      className={`button is-dark ${exportStatus.loading ? 'is-loading' : ''} `}
    >
      Export as PDF
    </button>
  );
};
