/**
 * @file Disclaimer text component
 */
import React from 'react';

/**
 * Disclaimer component for Study page
 *
 * @param {number} age The age of the patient
 * @param  {boolean} scannerIsValid Whether the scanner is valid
 * @returns {object} React element
 */
const DisclaimerData = ({ age, scannerIsValid }) => {
  return (
    <span style={{ padding: '0.75rem', color: 'red' }}>
      <span>NOT FOR CLINICAL USE </span>
      {!scannerIsValid && <span>Scanner model not validated </span>}
      {age && <span>Age is outside validated age range </span>}
    </span>
  );
};

export default DisclaimerData;
