/**
 * @file Footer component
 */
import React from 'react';
import ce from './ce.svg';
import factory from './factory.png';

/** Login page login request function
 *
 * @returns {object} React element
 */
const Footer = () => {
  return (
    <div className='study-list-footer'>
      <div style={{ display: 'flex' }}>
        <span>Version 2.1.2 (2-2031-5)</span>
        <span
          className='icon'
          style={{
            filter: 'invert(100)',
            marginLeft: '20px'
          }}
        >
          <img src={ce} alt='CE marking' style={{ maxWidth: '150%' }} />
        </span>
        <span id='regulatoryInformation' style={{ paddingLeft: '20px' }}>
          {/* If you change the id of this element make sure you also change it in Jenkins/replace_external.sh and Jenkins/replace_internal.sh */}
          {/* eslint-disable-next-line */}
          Apollo is regulated as a medical product. This product conforms to Medical Device Directive 93/42/EEC
        </span>
        <span className='icon' style={{ marginLeft: '20px' }}>
          <img src={factory} alt='Factory' style={{ filter: 'invert(1)' }} />
        </span>
        <span style={{ paddingLeft: '20px' }}>
          Cerebriu A/S Danneskiold-Samsøes Allé 41 DK-1434 Copenhagen K ©
          2020-2024 Cerebriu A/S
        </span>
        <span style={{ paddingLeft: '20px' }}>
          For support contact support@cerebriu.com
        </span>
      </div>
    </div>
  );
};

export default Footer;
