/**
 * @file Scanner page container
 */
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { MRStudyPageComponent } from 'components/studyPage/mrStudyPage';
import { ScannersContext } from 'containers/ScannersContext';
import { formatDate } from 'utils/dateFormat';
import { EMPTY_VIEWER_INFO_MR } from 'utils/constants';

/**
 * Function for checking for updates in the series array
 *
 * @param {object} oldSeries old series object
 * @param {object} newSeries new series object
 *
 * @returns {object} old series if nothing changed, new if it's different
 */
const compareSeries = (oldSeries, newSeries) => {
  if (JSON.stringify(oldSeries) !== JSON.stringify(newSeries)) {
    return newSeries;
  }
  return oldSeries;
};

/**
 * Study page top level containers
 *
 * @param {object} scannerData received about study
 * @param {Function} setPatientData function for setting patient data
 */
const updatePatientData = (scannerData, setPatientData) => {
  setPatientData(oldState => {
    let priority = {};
    if (scannerData.data.triage == null) {
      priority = {
        priority: 'Processing',
        priorityColor: ''
      };
    } else {
      priority = {
        priority: scannerData.data.triage.priority,
        priorityColor: scannerData.data.triage.color
      };
    }
    return {
      data: {
        ...scannerData.data,
        ...priority,
        studyId: scannerData.data._id,
        scannerId: scannerData.data.stationName,
        timestamp: formatDate(scannerData.data.timestamp.substr(0, 12)),
        completedSequences: compareSeries(
          oldState.data.completedSequences,
          scannerData.data.series
        )
      },
      loading: false,
      error: false
    };
  });
};

/**
 * Function to overwrite papaya viewer element with default info string for new study
 */
const resetPapaya = () => {
  if (
    Array.isArray(window.papayaContainers) &&
    window.papayaContainers.length
  ) {
    // Remove current viewer object and overwrite viewer div elem
    window.papaya.Container.resetViewer(0, []);
    window.papayaContainers.pop();
    let parent = document.getElementById('imageViewer');
    let defaultInfo = EMPTY_VIEWER_INFO_MR;
    if (parent) {
      parent.innerHTML = `<span class="image-view__default" >${defaultInfo}</span>`;
    }
  }
};

/**
 * Study page top level containers
 *
 * @returns {object} React element
 */
const ScannerPage = ({ match, history }) => {
  const scannersContext = useContext(ScannersContext);
  const scannerId = match.params.scannerId;
  const [patientData, setPatientData] = useState({
    data: {},
    loading: true,
    error: false
  });
  const [findings, setFindings] = useState([]);
  const [transferStatus, setTransferStatus] = useState({});
  const [pacsEnabled, setPacsEnabled] = useState(false);
  const [disclaimer, setDisclaimer] = useState({
    basic: '',
    scanner: ''
  });
  const [resetMRViewerKey, setResetViewerKey] = useState(undefined);

  useEffect(() => {
    /**
     * Function for fetching scanner data
     */
    const fetchScannerData = async () => {
      try {
        const scannerData = await axios(`/api/scanner/${scannerId}`);
        if (scannerData.data.message) {
          setPatientData({
            data: {},
            loading: false,
            error: false,
            empty: true
          });
          return;
        }
        resetPapaya();
        setResetViewerKey(true);
        updatePatientData(scannerData, setPatientData);
        setFindings(scannerData.data.findings);
        setTransferStatus(scannerData.data.transferStatus);
        setPacsEnabled(scannerData.data.pacsEnabled);
        setDisclaimer({
          basic: scannerData.data.clinicalUseDisclaimer.ageDisclaimer,
          scanner: scannerData.data.clinicalUseDisclaimer.scannerValid
        });
      } catch (error) {
        setPatientData({
          data: {},
          loading: false,
          error: true
        });
      }
    };
    fetchScannerData();
  }, [scannerId]);

  useEffect(() => {
    /**
     * Function for refetching scanner data
     *
     * @returns {Function} clears the timeout
     */
    const setRefetch = () => {
      const timeout = setTimeout(() => {
        refetchScannerData();
      }, 5000);

      return () => {
        clearTimeout(timeout);
      };
    };

    /**
     * Function for fetching sequence data
     */
    const refetchScannerData = async () => {
      try {
        const sequences = await axios(`/api/scanner/${scannerId}`);
        if (sequences.data.message) {
          return setRefetch();
        }
        if (patientData.data.patientId !== sequences.data.patientId) {
          if (
            !window.confirm(
              "There's a new study on this scanner. Press OK to monitor new study or press Cancel to keep monitoring current study"
            )
          ) {
            const studyId = patientData.data._id;
            history.push(`/study/${studyId}`);
          } else {
            resetPapaya();
            setResetViewerKey(true);
          }
        } else {
          setResetViewerKey(false);
        }
        updatePatientData(sequences, setPatientData);
        setFindings(sequences.data.findings);
        setTransferStatus(sequences.data.transferStatus);
        setPacsEnabled(sequences.data.pacsEnabled);
        setDisclaimer({
          basic: sequences.data.clinicalUseDisclaimer.ageDisclaimer,
          scanner: sequences.data.clinicalUseDisclaimer.scannerValid
        });
      } catch (error) {
        setPatientData({
          ...patientData,
          loading: false,
          error: true
        });
      }
    };
    if (!patientData.error) {
      return setRefetch();
    }
  }, [history, patientData, scannerId]);

  if (patientData.loading) {
    return 'Establishing connection to scanner';
  }
  if (patientData.error) {
    return 'Connection to scanner failed';
  }
  if (patientData.empty) {
    return 'No studies on this scanner yet';
  }

  let studyPage = {};
  if (patientData.data.modality === 'MR') {
    studyPage = (
      <MRStudyPageComponent
        studyData={patientData.data}
        completedSequences={patientData.data.completedSequences}
        processingSequences={[]}
        findings={findings}
        disclaimer={disclaimer}
        pacsEnabled={pacsEnabled}
        transferStatus={transferStatus}
        resetViewerKey={resetMRViewerKey}
      />
    );
  } else {
    studyPage = <div>Modality not supported</div>;
  }
  const scannerName = scannersContext.getScannerDescription(scannerId);
  return (
    <>
      <div style={{ padding: '0.75rem' }}>
        Monitoring scanner: {scannerName}
      </div>
      {studyPage}
    </>
  );
};

export default ScannerPage;
