/**
 * @file Single study page component
 */
import React, { useState, useEffect } from 'react';

import PatientData from 'components/patientData';
import { SequenceList } from 'components/sequenceList';
import { MRTable } from 'components/findingTable/mrTable';
import { PdfButton } from 'components/buttons/pdfButton';
import { TransferButton } from 'components/buttons/transferButton';
import DisclaimerComponent from 'components/disclaimer';
import {
  imageViewerWithoutOverlay,
  imageViewerWithOverlay
} from 'utils/imageViewers';
import { EMPTY_VIEWER_INFO_MR } from 'utils/constants';

/**
 * Study page component
 *
 * @function
 * @param {object} studyData studies from the studies context
 * @param {object} completedSequences viewable series in the study
 * @param {Array} processingSequences unviewable series in the study
 * @param {Array} findings array of findings
 * @param {boolean} pacsEnabled whether PACS transfer is enabled
 * @param {object} transferStatus object with PACS transfer info
 * @param {boolean} resetViewerKey whether to reset the papaya viewer params
 * @returns {object} React element
 */
export const MRStudyPageComponent = ({
  studyData,
  completedSequences,
  processingSequences,
  findings,
  pacsEnabled,
  transferStatus,
  resetViewerKey
}) => {
  const [viewerKey, setViewerKey] = useState({
    seriesId: '',
    findingIndex: false,
    scanPlane: ''
  });

  useEffect(() => {
    if (resetViewerKey) {
      setViewerKey({
        seriesId: '',
        findingIndex: false,
        scanPlane: ''
      });
    }
  }, [resetViewerKey]);

  /**
   * Function for loading image in viewer without overlay
   *
   * @function
   * @param {string} seriesId for sequence
   * @param {number} findingIndex for finding
   * @param {string} scanPlane for sequence
   */
  const loadWithOverlay = (seriesId, findingIndex, scanPlane) => {
    imageViewerWithOverlay(
      studyData.studyId,
      seriesId,
      findingIndex,
      findings,
      studyData.modality,
      scanPlane
    );
    setViewerKey({
      seriesId: seriesId,
      findingIndex: findingIndex
    });
  };

  /**
   * Function for loading image in viewer
   *
   * @function
   * @param {string} seriesId for sequence
   * @param {string} scanPlane for sequence
   */
  const loadWithoutOverlay = (seriesId, scanPlane) => {
    imageViewerWithoutOverlay(studyData.studyId, seriesId, scanPlane);
    setViewerKey({
      seriesId: seriesId,
      findingIndex: false
    });
  };

  /**
   * Function for loading an image in papaya without overlay
   *
   * @function
   * @param {string} id for sequence
   * @returns {string} sequence key name for given id
   */
  const getSequenceNameById = id => {
    let completed = completedSequences.find(seq => seq._id === id);
    if (completed) {
      return completed.cerebriuSequenceKey;
    }
    let processing = processingSequences.find(seq => seq._id === id);
    if (processing) {
      return processing.cerebriuSequenceKey;
    }
    return '';
  };

  /**
   * Function for finding the scan plane of a series
   *
   * @function
   * @param {string} id for sequence
   * @returns {string} scan plane for given id
   */
  const getScanPlaneById = id => {
    let completed = completedSequences.find(seq => seq._id === id);
    if (completed) {
      return completed.scanPlane;
    }
    let processing = processingSequences.find(seq => seq._id === id);
    if (processing) {
      return processing.scanPlane;
    }
    return 'Not found';
  };

  const showDisclaimer =
    studyData.clinicalUseDisclaimer.ageDisclaimer ||
    !studyData.clinicalUseDisclaimer.scannerValid;
  const enablePdfButton = findings.length >= 1;
  const enablePACSButton =
    findings.some(f => f.segmentations.length >= 1) && pacsEnabled;
  return (
    <div className='has-navbar-fixed-top'>
      <section className='section study-result'>
        <div className='columns'>
          <div className='column'>
            <div className='exports' style={{ display: 'inline-flex' }}>
              <PdfButton
                studyId={studyData.studyId}
                enabled={enablePdfButton}
              />
              <TransferButton
                studyId={studyData.studyId}
                enabled={enablePACSButton}
                transfer={transferStatus}
                iconEnabler={pacsEnabled}
              />
            </div>
            <div className='info-view'>
              <div className='columns'>
                <PatientData
                  patientName={studyData.patientName}
                  patientId={studyData.patientId}
                  timestamp={studyData.timestamp}
                  modality={studyData.modality}
                  scannerDescription={studyData.scannerDescription}
                  triage={studyData.priority}
                  triageColor={studyData.priorityColor}
                />
                <SequenceList
                  completedSequences={completedSequences}
                  processingSequences={processingSequences}
                  noOverlayFunction={loadWithoutOverlay}
                  viewerKey={viewerKey}
                />
              </div>
            </div>
            {showDisclaimer && (
              <DisclaimerComponent
                age={studyData.clinicalUseDisclaimer.ageDisclaimer}
                scannerIsValid={studyData.clinicalUseDisclaimer.scannerValid}
              />
            )}
            <div className='result-view'>
              <MRTable
                findings={findings}
                clickFunction={loadWithOverlay}
                viewerKey={viewerKey}
                getSequenceNameById={getSequenceNameById}
                getScanPlaneById={getScanPlaneById}
              />
            </div>
          </div>
          <div className='column'>
            <div className='image-view'>
              <div id='imageViewer'>
                <span className='image-view__default'>
                  {EMPTY_VIEWER_INFO_MR}
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
