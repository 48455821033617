/**
 * @file Filters file for study list page
 */
import React from 'react';
import { formatDate } from 'utils/dateFormat';

/** Filter for name column in Study list page
 *
 * @function
 * @param  {object} column which has to be filtered
 * @returns {object} React filter element
 */
export const NameFilter = ({
  column: { filterValue, preFilteredRows, setFilter, id }
}) => {
  const count = preFilteredRows.length;
  return (
    <input
      data-testid={id}
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search among ${count} records`}
    />
  );
};

/** Filter for select column in Study list page
 *
 * @function
 * @param {object} column which has to be filtered
 * @returns {object} React filter element
 */
export const SelectFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id }
}) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value=''>All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

/** Filter for triage select column in Study list page
 *
 * @function
 * @param {object} column which has to be filtered
 * @returns {object} React filter element
 */
export const SelectFilterTriage = ({
  column: { filterValue, setFilter, preFilteredRows, id }
}) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Map();
    preFilteredRows.forEach(row => {
      options.set(row.values[id], row.original['triageName']);
    });
    return [...options.entries()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      data-testid='TriageFilter'
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value=''>All</option>
      {options.map((option, i) => (
        <option key={i} value={option[0]}>
          {option[1]}
        </option>
      ))}
    </select>
  );
};

/** Function which matches user's text input in the Study date/time column to the formatted
 *  study date time, so that the filter functions as expected by a user
 *
 * @function
 * @param {object} rows of studies in the study list
 * @param {object} ids of the properties in a study
 * @param {object} filterValue the inputted value to filter studies by
 * @returns {object} the filtered rows
 */
export const studydatetimeText = (rows, ids, filterValue) => {
  rows = rows.filter(row => {
    return ids.some(id => {
      const rowValue = formatDate(String(row.values[id]));
      return rowValue.toLowerCase().includes(String(filterValue).toLowerCase());
    });
  });
  return rows;
};
