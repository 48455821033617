/**
 * @file Columns file for study list page
 */
import { SelectFilter, SelectFilterTriage, studydatetimeText } from './filters';

export const Columns = [
  {
    Header: 'Read/Unread',
    accesor: 'readunread',
    className: 'readunread'
  },
  {
    Header: 'Patient Name',
    accessor: 'patientname',
    className: 'patientname'
  },
  {
    Header: 'Patient ID',
    accessor: 'patientid',
    className: 'patientid'
  },
  {
    Header: 'Study Date/Time',
    accessor: 'studydatetime',
    className: 'studydatetime',
    filter: studydatetimeText
  },
  {
    Header: 'Modality',
    accessor: 'modality',
    className: 'modality',
    Filter: SelectFilter
  },
  {
    Header: 'Scanner',
    accessor: 'scanner',
    className: 'scanner',
    Filter: SelectFilter,
    filter: 'includes'
  },
  {
    Header: 'Study Summary',
    accessor: 'studysummary',
    className: 'studysummary'
  },
  {
    Header: 'Triage',
    accessor: 'triage',
    className: 'priority',
    Filter: SelectFilterTriage,
    filter: 'equals'
  },
  {
    Header: 'Disclaimer',
    accessor: 'disclaimer',
    className: 'disclaimer',
    Filter: SelectFilter
  }
];
