/**
 * @file SSE event name constants
 */
export const UI_SERIES_RECEIVED = 'UI_SERIES_RECEIVED';
export const UI_INFERENCE_FINISHED = 'UI_INFERENCE_FINISHED';
export const UI_CONVERSION_SUBSAMPLED = 'UI_CONVERSION_SUBSAMPLED';
export const UI_INFERENCE_SUBSAMPLED = 'UI_INFERENCE_SUBSAMPLED';
export const UI_TRANSFER_ONGOING = 'UI_TRANSFER_ONGOING';
export const UI_TRANSFER_FINISHED = 'UI_TRANSFER_FINISHED';
export const UI_STUDY_MOVED = 'UI_STUDY_MOVED';
