/**
 * @file papaya image viewer utilities
 * Additional documentation can be found at https://github.com/rii-mango/Papaya
 */

/**
 * Function for loading an image in papaya without overlay
 *
 * @function
 * @param {string} studyId the ID of the study
 * @param {string} seriesId the ID of the sequence to be loaded
 * @param {string} scanPlane scan plane of the nifti
 */
export const imageViewerWithoutOverlay = (studyId, seriesId, scanPlane) => {
  var params = [];
  params['images'] = [`/api/studyImage/${studyId}/${seriesId}`];
  params['mainView'] = scanPlane;
  params['radiological'] = true;
  params['ignoreNiftiTransforms'] = true;
  window.papaya.Container.addViewer('imageViewer', params);
};

/**
 * Function for loading an image in papaya with overlay
 *
 * @function
 * @param {string} studyId ID of the study that has the series
 * @param {string} seriesId ID of the series to be loaded
 * @param {number} findingIndex index of finding to open
 * @param {Array} findings the data from the mongodb database
 * @param {string} modality modality of the study
 * @param {string} scanPlane scan plane of the nifti
 */
export const imageViewerWithOverlay = (
  studyId,
  seriesId,
  findingIndex,
  findings,
  modality,
  scanPlane
) => {
  let params = [];
  params['images'] = [
    `/api/studyImage/${studyId}/${seriesId}`,
    `/api/studyOverlay/${studyId}/${seriesId}/${findingIndex}`
  ];
  // Define red color for the overlay
  params['luts'] = [
    {
      name: 'Overlay (Red)',
      data: [
        [0, 1, 0, 0],
        [1, 1, 0, 0]
      ]
    }
  ];
  if (modality === 'MR') {
    let largestFind = findings[findingIndex].segmentations.find(
      segmentation => segmentation.seriesId === seriesId
    ).niftiSegmentation.subSampled.largestFinding;
    params['coordinate'] = [
      largestFind.sagittal,
      largestFind.coronal,
      largestFind.axial
    ];
  }
  // Papaya picks up on the last part of the url as the file name, which is the findingIndex
  params[findingIndex] = { lut: 'Overlay (Red)' };
  params['mainView'] = scanPlane;
  params['radiological'] = true;
  params['ignoreNiftiTransforms'] = true;
  window.papaya.Container.addViewer('imageViewer', params);
};
