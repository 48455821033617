/**
 * @file Scanner dropdown component
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import shortid from 'shortid';
import onClickOutside from 'react-onclickoutside';

// not an arrow function due to bug in react-onclickoutside
// doesn't matter too much, but if https://github.com/Pomax/react-onclickoutside/issues/327 is solved, apply arrow function pattern

/** @class Dropdown representing a dropdown component*/
class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: null };
  }

  /** Handler to close scanner outside dropdown
   *
   */
  handleClickOutside = () => {
    this.props.closeScanners();
  };

  /** Function to handle scanner selection
   *
   * @param {number} scanner id to set as selected
   */
  onSelection = scanner => {
    this.setState({
      selected: scanner
    });
  };

  render() {
    const { isActive, onScannersClick, scanners } = this.props;
    return (
      <div
        className={'navbar-item has-dropdown ' + (isActive ? 'is-active' : '')}
      >
        <span
          role='button'
          tabIndex='0'
          className='navbar-link js-toggle-dropdown'
          onClick={onScannersClick}
          onKeyDown={onScannersClick}
        >
          Select scanner
        </span>
        <div className='navbar-dropdown scanner-list'>
          <div id='monitorNavigation'>
            {scanners &&
              scanners.map(scanner => {
                return (
                  <div className='navbar-item' key={shortid.generate()}>
                    <div className='control'>
                      <label className='radio'>
                        <input
                          type='radio'
                          data-text={scanner.scannerDescription}
                          value={scanner.scannerId}
                          checked={this.state.selected === scanner.scannerId}
                          onChange={this.onSelection.bind(
                            this,
                            scanner.scannerId
                          )}
                        />
                        {scanner.scannerDescription}
                      </label>
                    </div>
                  </div>
                );
              })}
          </div>
          <hr className='navbar-divider' />
          <div className='navbar-item'>
            <Link
              className='js-show-studyResult button is-light is-fullwidth'
              to={`/scanner/${this.state.selected}`}
            >
              Monitor
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default onClickOutside(Dropdown);
