/**
 * @file Application context for reusing studies data across various pages
 */
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import { EventsContext } from 'containers/EventsContext';
import {
  UI_SERIES_RECEIVED,
  UI_INFERENCE_FINISHED,
  UI_INFERENCE_SUBSAMPLED,
  UI_STUDY_MOVED,
  UI_TRANSFER_ONGOING,
  UI_TRANSFER_FINISHED
} from 'containers/EventsContext/events';
import { formatDate } from 'utils/dateFormat';

export const StudiesContext = React.createContext({});

/**
 * Function for altering the timestamp formatting while also
 * keeping the original timestamp needed for sorting
 *
 * @param {object} study study obejct with study information
 * @returns {object} Study with correct timestamp formatting
 */
const formatDateStudy = study => {
  if (!('timestampOri' in study)) {
    // set only timestampOri once (the first time formatDateStudy is called),
    // otherwise it will set it to the formatted study date
    study.timestampOri = study.timestamp;
  }
  study.timestamp = formatDate(study.timestamp);
  return study;
};

/**
 * Studies Provider top level component
 *
 * @function
 * @returns {object} React element
 */
export const StudiesProvider = ({ children }) => {
  const [studies, setStudies] = useState({ unread: [], read: [] });
  const eventsContext = useContext(EventsContext);

  const studiesRef = React.useRef(studies);
  // Used by React-table to hold filter selection
  const tableRef = React.useRef();

  useEffect(() => {
    tableRef.current = false;
  }, [studies]);

  useEffect(() => {
    /**
     * Study list fetching function
     *
     */
    const fetchData = async () => {
      const resultUnread = await axios('/api/studylistUnread');
      const resultRead = await axios('/api/studylistRead');
      studiesRef.current = {
        unread: resultUnread.data.studyList,
        read: resultRead.data.studyList
      };
      setStudies({
        unread: resultUnread.data.studyList.map(formatDateStudy),
        read: resultRead.data.studyList.map(formatDateStudy)
      });
    };
    fetchData();
  }, []);

  useEffect(() => {
    /** Function to get a Studylist entry from a detailed study info
     *
     * @param {object} study Detailed study data
     * @returns {object} Studylist entry
     */
    const studyInfoToStudyList = study => {
      if (!study.triage) {
        study.triage = {
          priority: 'Processing',
          priorityId: -1
        };
      }
      return {
        patientName: study.patientName,
        patientId: study.patientId,
        timestamp: formatDate(study.timestamp),
        timestampOri: study.timestamp,
        scannerId: study.scannerId,
        scannerDescription: study.scannerDescription,
        studyDescription: study.studyDescription,
        priority: study.triage.priority,
        priorityColor: study.triage.color,
        priorityId: study.triage.priorityId,
        studyId: study['_id'],
        clinicalUseDisclaimer: study.clinicalUseDisclaimer,
        modality: study.modality,
        status: study.status
      };
    };

    /**
     * Function to replace a Studylist entry from a detailed study info
     *
     * @param {object} newStudy study data
     * @param {string} state whether the entry is currently in the read or unread study list
     */
    const replaceStudy = (newStudy, state) => {
      tableRef.current = true;
      if (state === 'unread') {
        setStudies(studiesMap => {
          let newStudiesUnread = [
            ...studiesMap.unread.map(study =>
              study.studyId === newStudy.studyId ? newStudy : study
            )
          ];
          newStudiesUnread = newStudiesUnread.sort(
            (a, b) => b.priorityId - a.priorityId
          );
          studiesRef.current = {
            unread: newStudiesUnread,
            read: studiesMap.read
          };
          return studiesRef.current;
        });
      } else if (state === 'read') {
        setStudies(studiesMap => {
          let newStudiesRead = [
            ...studiesMap.read.map(study =>
              study.studyId === newStudy.studyId ? newStudy : study
            )
          ];
          newStudiesRead = newStudiesRead.sort(
            (a, b) => b.priorityId - a.priorityId
          );
          studiesRef.current = {
            unread: studiesMap.unread,
            read: newStudiesRead
          };
          return studiesRef.current;
        });
      } else {
        addStudy(newStudy);
      }
    };

    /**
     * Function to move an unread Studylist entry to the read studylist or vice versa
     *
     * @param {object} eventData data of study to move event
     */
    const studyMovedHandler = eventData => {
      const newStudy = studyInfoToStudyList(JSON.parse(eventData.data));
      tableRef.current = true;
      const newStudyUnread = studiesRef.current.unread.find(
        study => study.studyId === newStudy.studyId
      );
      setStudies(studiesMap => {
        var newStudiesUnread, newStudiesRead;
        if (newStudyUnread) {
          newStudiesUnread = [
            ...studiesMap.unread.filter(
              study => study.studyId !== newStudy.studyId
            )
          ];
          studiesMap.read.push(newStudy);
          newStudiesRead = studiesMap.read.sort(
            (a, b) => b.priorityId - a.priorityId
          );
        } else {
          newStudiesRead = [
            ...studiesMap.read.filter(
              study => study.studyId !== newStudy.studyId
            )
          ];
          studiesMap.unread.push(newStudy);
          newStudiesUnread = studiesMap.unread.sort(
            (a, b) => b.priorityId - a.priorityId
          );
        }
        studiesRef.current = {
          unread: newStudiesUnread,
          read: newStudiesRead
        };
        return studiesRef.current;
      });
    };

    /** Handler function for a new series event
     *
     * @param {object} eventData data of series received event
     */
    const newSeriesHandler = eventData => {
      const newStudy = studyInfoToStudyList(JSON.parse(eventData.data));
      const existingUnreadStudy = studiesRef.current.unread.find(
        study => study.studyId === newStudy.studyId
      );
      const existingReadStudy = studiesRef.current.read.find(
        study => study.studyId === newStudy.studyId
      );
      if (existingUnreadStudy) {
        replaceStudy(newStudy, 'unread');
      } else if (existingReadStudy) {
        replaceStudy(newStudy, 'read');
      } else {
        addStudy(newStudy);
      }
    };

    /** Function to replace a single study in studylist once inference is finished
     *
     * @param {object} eventData SSE message
     */
    const inferenceHandler = eventData => {
      const processedStudy = studyInfoToStudyList(JSON.parse(eventData.data));
      const existingUnreadStudy = studiesRef.current.unread.find(
        study => study.studyId === processedStudy.studyId
      );
      if (existingUnreadStudy) {
        replaceStudy(processedStudy, 'unread');
      } else {
        replaceStudy(processedStudy, 'read');
      }
    };

    eventsContext.registerHandler(UI_SERIES_RECEIVED, newSeriesHandler);
    eventsContext.registerHandler(UI_INFERENCE_FINISHED, inferenceHandler);
    eventsContext.registerHandler(UI_INFERENCE_SUBSAMPLED, inferenceHandler);
    eventsContext.registerHandler(UI_STUDY_MOVED, studyMovedHandler);
    eventsContext.registerHandler(UI_TRANSFER_ONGOING, inferenceHandler);
    eventsContext.registerHandler(UI_TRANSFER_FINISHED, inferenceHandler);
  }, [eventsContext]);

  /** Function to add a single study to the list of unread studies
   *
   * @param {object} newStudy new study data
   */
  const addStudy = newStudy => {
    tableRef.current = true;
    setStudies(studies => {
      const newStudies = [...studies.unread, newStudy].sort(
        (a, b) => b.priorityId - a.priorityId
      );
      studiesRef.current = { unread: newStudies, read: studies.read };
      return studiesRef.current;
    });
  };

  /**
   * Study list filter for single study by id
   *
   * @param {number} studyId of required study
   * @returns {object} study object
   */
  const getSingleStudy = studyId => {
    const study = studies.unread.find(study => study.studyId === studyId);
    if (study) {
      return study;
    }
    return studies.read.find(study => study.studyId === studyId);
  };

  return (
    <StudiesContext.Provider value={{ studies, getSingleStudy, tableRef }}>
      {children}
    </StudiesContext.Provider>
  );
};
