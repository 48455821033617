/**
 * @file Single study page container
 */
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';

import { EventsContext } from 'containers/EventsContext';
import {
  UI_SERIES_RECEIVED,
  UI_CONVERSION_SUBSAMPLED,
  UI_INFERENCE_FINISHED,
  UI_INFERENCE_SUBSAMPLED,
  UI_TRANSFER_ONGOING,
  UI_TRANSFER_FINISHED
} from 'containers/EventsContext/events';
import { MRStudyPageComponent } from 'components/studyPage/mrStudyPage';

/**
 * Study page top level containers
 *
 * @param {object} studies studies from the studies context
 * @returns {object} React element
 */
const StudyPage = ({ studyData }) => {
  const eventsContext = useContext(EventsContext);

  const [completedSequences, setCompletedSequences] = useState([]);
  const [findings, setFindings] = useState([]);
  const [transferStatus, setTransferStatus] = useState({});
  const [pacsEnabled, setPacsEnabled] = useState(false);

  useEffect(() => {
    /**
     * Function for fetching sequence data
     */
    const fetchSequences = async () => {
      const sequences = await axios(`/api/studyInfo/${studyData.studyId}`);
      if (sequences && sequences.data) {
        setFindings(sequences.data.findings);
        setCompletedSequences(sequences.data.series);
        setTransferStatus(sequences.data.transferStatus);
        setPacsEnabled(sequences.data.pacsEnabled);
      }
    };
    fetchSequences();
  }, [studyData.studyId]);

  useEffect(() => {
    /**
     * Function to replace a Studylist entry from a detailed study info
     *
     * @param {object} eventData data from event
     */
    const studyPageHandler = eventData => {
      let processedStudy = JSON.parse(eventData.data);
      if (processedStudy['_id'] === studyData.studyId) {
        setFindings(processedStudy.findings);
        setCompletedSequences(processedStudy.series);
        setTransferStatus(processedStudy.transferStatus);
        setPacsEnabled(processedStudy.pacsEnabled);
      }
    };
    eventsContext.registerHandler(UI_SERIES_RECEIVED, studyPageHandler);
    eventsContext.registerHandler(UI_CONVERSION_SUBSAMPLED, studyPageHandler);
    eventsContext.registerHandler(UI_INFERENCE_FINISHED, studyPageHandler);
    eventsContext.registerHandler(UI_INFERENCE_SUBSAMPLED, studyPageHandler);
    eventsContext.registerHandler(UI_TRANSFER_ONGOING, studyPageHandler);
    eventsContext.registerHandler(UI_TRANSFER_FINISHED, studyPageHandler);
    return () => {
      eventsContext.unregisterHandler(UI_SERIES_RECEIVED, studyPageHandler);
      eventsContext.unregisterHandler(
        UI_CONVERSION_SUBSAMPLED,
        studyPageHandler
      );
      eventsContext.unregisterHandler(UI_INFERENCE_FINISHED, studyPageHandler);
      eventsContext.unregisterHandler(
        UI_INFERENCE_SUBSAMPLED,
        studyPageHandler
      );
      eventsContext.unregisterHandler(UI_TRANSFER_ONGOING, studyPageHandler);
      eventsContext.unregisterHandler(UI_TRANSFER_FINISHED, studyPageHandler);
    };
  }, [studyData.studyId, eventsContext]);

  if (studyData.modality === 'MR') {
    return (
      <MRStudyPageComponent
        studyData={studyData}
        completedSequences={completedSequences}
        processingSequences={[]}
        findings={findings}
        pacsEnabled={pacsEnabled}
        transferStatus={transferStatus}
      />
    );
  } else {
    return <div>Modality not supported</div>;
  }
};

export default StudyPage;
