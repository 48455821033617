/**
 * @file Context for SSE connection to backend
 */
import React, { useEffect, useState } from 'react';

export const EventsContext = React.createContext({});

/**
 * Events Provider top level component
 *
 * @function
 *
 * @param {string} eventEndpoint URL for EventSource connection in backend
 *
 * @returns {object} React element
 */
export const EventsProvider = ({ eventEndpoint, children }) => {
  let [eventManager, setEventManager] = useState({
    connected: false
  });

  useEffect(() => {
    setEventManager(() => {
      let source = new EventSource(eventEndpoint);
      window.onbeforeunload = () => {
        source.close();
      };
      return {
        eventSource: source,
        connected: true
      };
    });
  }, [eventEndpoint]);

  /** Function to register an event stream handler
   *
   * @param {string} event Event to listen in EventSource
   * @param {Function} handler Handler function to call after receiving event
   */
  const registerHandler = (event, handler) => {
    if (eventManager.connected) {
      eventManager.eventSource.addEventListener(event, handler);
    }
  };

  /** Function to remove a handler form event stream
   *
   * @param {string} event Event to listen in EventSource
   * @param {Function} handler Handler function to call after receiving event
   */
  const unregisterHandler = (event, handler) => {
    if (eventManager.connected) {
      eventManager.eventSource.removeEventListener(event, handler);
    }
  };

  return (
    <EventsContext.Provider value={{ registerHandler, unregisterHandler }}>
      {children}
    </EventsContext.Provider>
  );
};
