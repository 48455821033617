/**
 * @file Unauthorized SSO page element
 */
import React from 'react';

/** SSO Unauthorized to Apollo element
 *
 * @function
 * @param  {Function} logout SLO function
 * @returns {object} React element
 */
export const UnauthorizedSso = ({ logout }) => {
  return (
    <section className='hero is-fullheight'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns is-centered'>
            <div className='column is-5-tablet is-4-desktop is-3-widescreen'>
              <form className='box'>
                <div className='field'>
                  <div className='control'>
                    <div className='label'>
                      You are not authorized by your organization to use Apollo.
                    </div>
                  </div>
                </div>
                <div className='field'>
                  <div className='has-text-centered'>
                    <button className='button is-dark active' onClick={logout}>
                      Logout from SAML
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
