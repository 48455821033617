/**
 * @file Header component
 */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import DropdownComponent from 'components/dropdown';
import { getDateTime } from 'utils/dateFormat';

/**
 * @param  {object} params for scanner activity
 * @param  {string} params.username to display
 * @param  {boolean} params.isAuthorized for authorized part
 * @param  {Function} params.logout for button
 * @param  {Function} params.closeScanners for dropdown
 * @param  {Function} params.onScannersClick for dropdown
 * @param  {boolean} params.scannerDropdownActive for scanner dropdown
 * @returns {object} React element
 */
const HeaderComponent = ({ scanners, username, isAuthorized, logout }) => {
  const [currentTime, setCurrentTime] = useState(getDateTime());
  const [scannerDropdownActive, setScannerDropdownActive] = useState(false);

  setInterval(() => {
    setCurrentTime(getDateTime(false));
  }, 1000);

  /**
   * Function to set dropdown active selection
   *
   * @param {object} event button element default event
   */
  const onScannersClick = event => {
    event.preventDefault();
    if (!scanners || scanners.length === 0) {
      console.log('No scanners detected');
      return;
    }
    setScannerDropdownActive(!scannerDropdownActive);
  };

  /** Function to close scanners dropdown
   *
   */
  const closeScanners = () => {
    setScannerDropdownActive(false);
  };

  return (
    <nav
      className='navbar is-black'
      role='navigation'
      aria-label='main navigation'
    >
      <div className='navbar-brand'>
        <Link className='navbar-item' to='/'>
          Apollo <sup>TM</sup> <span className='navbar__page'></span>
        </Link>
        <button
          className='navbar-burger burger'
          aria-label='menu'
          aria-expanded='false'
          data-target='navMenu'
        >
          <span aria-hidden='true'></span>
          <span aria-hidden='true'></span>
          <span aria-hidden='true'></span>
        </button>
      </div>

      {isAuthorized && (
        <div className='navbar-menu'>
          <div className='navbar-start'>
            <div className='navbar-item'>
              <Link className='button is-dark active' to='/studies'>
                Study List
              </Link>
            </div>
            <DropdownComponent
              isActive={scannerDropdownActive}
              onScannersClick={onScannersClick}
              closeScanners={closeScanners}
              scanners={scanners}
            />
          </div>

          <div className='navbar-end'>
            <div className='navbar-item'>
              <span className='date'>
                Date: <span className='js-currentDate'>{currentTime}</span>
              </span>
            </div>
            <React.Fragment>
              <div className='navbar-item'>
                <span className='user'>User: {username}</span>
              </div>
              <div className='navbar-item'>
                <button className='button is-dark active' onClick={logout}>
                  Logout
                </button>
              </div>
            </React.Fragment>
          </div>
        </div>
      )}
    </nav>
  );
};

export default HeaderComponent;
