/**
 * @file Scanner page context
 */
import React, { useState, useEffect } from 'react';
import axios from 'axios';

export const ScannersContext = React.createContext({});

/**
 * Scanners provider to access scanners in Apollo
 *
 * @returns {object} React element
 */
export const ScannersProvider = ({ children, authorized }) => {
  const [scanners, setScanners] = useState([]);

  /**
   * Function for getting a scanner name by id
   *
   * @function
   * @param {string} scannerId the ID of the scanner to be return
   * @returns {string} scanner name
   */
  const getScannerDescription = scannerId => {
    if (scanners && scanners.length > 0) {
      return scanners.find(x => x.scannerId === scannerId).scannerDescription;
    }
    return '';
  };

  useEffect(() => {
    /** Function to fetch scanners
     *
     */
    const fetchScanners = async () => {
      if (authorized) {
        const result = await axios('/api/scanners');
        setScanners(result.data.scannerList);
      }
    };

    fetchScanners();
  }, [authorized]);

  return (
    <ScannersContext.Provider value={{ scanners, getScannerDescription }}>
      {children}
    </ScannersContext.Provider>
  );
};
