/**
 * @file Sequence item component
 */
import React from 'react';

/**
 * Study page top level component
 *
 * @function
 * @param {object} sequence to be displayed
 * @returns {object} React element
 */
export const SequenceItem = ({
  sequence,
  clickFunction,
  isCompleted,
  isSelected
}) => {
  return (
    <li className={'result-view__view ' + (isSelected ? 'active' : '')}>
      {/* {!isCompleted && <i className='fas fa-spinner fa-pulse'></i>}
      {isCompleted && <i className='fas fa-check'></i>} */}
      <span
        role='button'
        tabIndex='0'
        className='studyName'
        onClick={e => {
          clickFunction(sequence._id, sequence.scanPlane);
        }}
        onKeyDown={clickFunction}
      >
        <span>{sequence.cerebriuSequenceKey}</span>
      </span>
    </li>
  );
};
