/**
 * @file Transfer to PACS button component
 */
import React, { useState, useEffect } from 'react';
import axios from 'axios';

/**
 * Transfer to PACS button component
 *
 * @function
 * @param {number} studyId study id of study to be exported
 * @param {boolean} enabled whether the transfer button should be enabled
 * @param {object} transfer the transfer object with status and error
 * @param {boolean} iconEnabler whether the transfer icon shoud be enabled
 * @returns {object} React element
 */
export const TransferButton = ({ studyId, enabled, transfer, iconEnabler }) => {
  const [transferStatus, setTransferStatus] = useState({
    ready: enabled,
    completed: false,
    ongoing: false,
    failed: false,
    waiting: false,
    error: ''
  });

  useEffect(() => {
    if (transfer) {
      setTransferStatus({
        ...transferStatus,
        completed: transfer.status === 'completed',
        failed: transfer.status === 'failed',
        ongoing: transfer.status === 'ongoing',
        waiting: transfer.status === 'waiting',
        error: transfer.error
      });
    }
  }, [transfer]);

  useEffect(() => {
    setTransferStatus({
      ...transferStatus,
      ready: enabled
    });
  }, [enabled]);

  /**
   * Function for requesting transfer to PACS of the study overlays
   *
   * @function
   */
  const sendToPACS = async () => {
    setTransferStatus({
      ...transferStatus,
      ongoing: true,
      failed: false,
      error: ''
    });

    await axios(`/api/study/${studyId}/transfer`).catch(err => {
      setTransferStatus({
        ...transferStatus,
        failed: true,
        error: err.response.data.message
      });
    });
  };

  /**
   * Function for returning a transfer icon based on transferStatus
   *
   * @function
   * @returns {object} React element
   */
  const transferIcon = () => {
    if (!iconEnabler) {
      return '';
    } else if (transferStatus.failed) {
      return (
        <i
          className='warning-triangle transfer-icon fas fa-exclamation-triangle'
          title={transferStatus.error}
        ></i>
      );
    } else if (transferStatus.ongoing) {
      return (
        <i
          className='transfer-icon fas fa-exchange-alt'
          title='Transfer in progress'
        ></i>
      );
    } else if (transferStatus.completed) {
      return (
        <i
          className='success-check transfer-icon fas fa-check-circle'
          aria-hidden='true'
          title='Successful transfer'
        ></i>
      );
    }
    return '';
  };

  return (
    <div id='pacsTransfer'>
      <button
        id='transferButton'
        disabled={!transferStatus.ready || transferStatus.ongoing}
        title={
          enabled ? '' : 'No overlays to transfer or transfer option disabled'
        }
        onClick={sendToPACS}
        className='button is-dark'
      >
        Send to PACS
      </button>
      {transferIcon()}
    </div>
  );
};
